import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { Toaster } from 'react-hot-toast'
import DataDeletion from './views/pages/data-deletion'
import PrivacyPolicy from './views/pages/privacy-policy'
import TermsOfService from './views/pages/terms-of-service'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  return (
    <Suspense fallback={loading}>
      <div>
        <Toaster />
      </div>
      <Routes>
        <Route path="/" name="Login" element={<Login />} />
        <Route path="/data-deletion" name="DataDeletion" element={<DataDeletion />} />
        <Route path="/privacy-policy" name="PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/terms" name="Terms" element={<TermsOfService />} />
        <Route path="*" name="Home" element={<DefaultLayout />} />
      </Routes>
    </Suspense>
  )
}

export default App
