import React from 'react'

const DataDeletion = () => {
  // Function to handle data deletion
  const handleDeleteData = () => {
    // Add logic here to delete data
    alert('Data deleted successfully!')
  }

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Data Deletion</h2>
      <p style={styles.description}>Are you sure you want to delete all data?</p>
      <button style={styles.button} onClick={handleDeleteData}>
        Delete Data
      </button>
    </div>
  )
}

// Inline styles
const styles = {
  container: {
    textAlign: 'center',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    maxWidth: '400px',
    margin: '0 auto',
    marginTop: '50px',
  },
  heading: {
    fontSize: '24px',
    marginBottom: '15px',
  },
  description: {
    fontSize: '16px',
    marginBottom: '20px',
  },
  button: {
    backgroundColor: '#f44336',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
  },
}

export default DataDeletion
