import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { AuthProvider } from './context/AuthContext'
import { BrowserRouter, HashRouter, Route, Routes, useNavigate } from 'react-router-dom'
createRoot(document.getElementById('root')).render(
  <>
    <>
      <AuthProvider>
        <BrowserRouter>
          {/* <HashRouter> */}
          <Provider store={store}>
            <App />
          </Provider>
          {/* </HashRouter> */}
        </BrowserRouter>
      </AuthProvider>
    </>
  </>,
)

reportWebVitals()
